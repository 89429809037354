var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"polygon-input"},[_c('google-map',{staticClass:"map",attrs:{"containerStyles":"width: 100%; height: 100%; position: absolute;","map-config":{
			    zoom: 2,
			    center: { lat: _vm.initialLat, lng: _vm.initalLng },
				zoomControl: _vm.readonly ? false : true,
				mapTypeControl: _vm.readonly ? false : true,
				scaleControl: _vm.readonly ? false : true,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: _vm.readonly ? false : true,

				options: {
					gestureHandling: _vm.readonly ? 'none' : 'greedy' // 
				},
			  },"bounds":_vm.paths,"libraries":['drawing']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
			  var google = ref.google;
			  var map = ref.map;
return [_c('map-polygon',{attrs:{"google":google,"map":map,"paths":_vm.paths,"editable":_vm.readonly ? false : true},on:{"mapClick":_vm.handleMapClick,"pathChanged":_vm.handlePathChange}}),_vm._t("default",null,{"google":google,"map":map})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }