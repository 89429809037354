<template>
	<div class="polygon-input">
		<google-map
			class="map"
			containerStyles="width: 100%; height: 100%; position: absolute;"
			:map-config="{
			    zoom: 2,
			    center: { lat: initialLat, lng: initalLng },
				zoomControl: readonly ? false : true,
				mapTypeControl: readonly ? false : true,
				scaleControl: readonly ? false : true,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: readonly ? false : true,

				options: {
					gestureHandling: readonly ? 'none' : 'greedy' // 
				},
			  }"
			:bounds='paths'
            :libraries="['drawing']"
		>
			<template slot-scope="{ google, map }">
				<map-polygon :google="google" :map="map" :paths="paths" :editable="readonly ? false : true" @mapClick="handleMapClick" @pathChanged="handlePathChange"/>
				<slot
					:google="google"
					:map="map"
				></slot>
			</template>
		</google-map>
	</div>
</template>

<script>
	import GoogleMap from "@/components/maps/GoogleMap";
	import MapPolygon from "@/components/maps/MapPolygon";
	export default {
		name: "PolygonInput",
		components: {MapPolygon, GoogleMap},
		data(){
			return {
				paths:  []
			}
		},
		methods: {
			handleMapClick(mapEvent){
				this.paths.push({lat: mapEvent.latLng.lat(),lng: mapEvent.latLng.lng()});
				this.$emit('input',this.paths);
			},
			handlePathChange(newPath){
				const paths = [];
				newPath.forEach((path, i) => {
					paths.push({
						lat: path.lat(),
						lng: path.lng()
					});
				});
				this.paths = paths;
				this.$emit('input',this.paths);
			}
		},
		mounted()
		{
			this.paths = this.value;
		},
		watch:{
			value: {
				deep:true,
				handler: function(){
					this.paths = this.value;
				}
			}
		},
		props: {
			initialLat: {
				type: Number,
				default: 55.48
			},
			initalLng: {
				type: Number,
				default: 9.47
			},
			value: Array,
			readonly: {
				type: Boolean,
				default: false
			}
		}
	};
</script>

<style lang="scss" scoped>
	.map {
		flex: 2;
		position: relative;
		background: #DDD;
		min-height: 360px;
	}
</style>
