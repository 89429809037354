<template>
	<div class="field-datetime" :class="getClasses">
		<div class="form__group field">
			<input v-if="disabled" class="form__field" :id="id" type="text" :value="visibleDate" :disabled='disabled'>
			<datetime v-else class="form__field" type="datetime" :id="id" :name="name" v-model="localValue" :min-datetime="minDate" />
			<label :for="id" class="form__label">{{label}}</label>
			<div v-if="hasError" class="error">{{error}}</div>
		</div>
	</div>
</template>


<script>
	import { Datetime } from 'vue-datetime';
	import 'vue-datetime/dist/vue-datetime.css'
	export default {
		name: "FieldDatetime",
		components: {Datetime},
		props:{
			label: String,
			name: String,
			value: String|Number,
			error: String,
			type: {
				type: String,
				default: 'input'
			},
			classes: {
				default: 'col-md-6',
				type: String
			},
			disabled: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			hasError(){
				return typeof this.error !== 'undefined' && this.error !== '';
			},
			getClasses(){
				return [
					this.hasError ? this.classes+' has-error' : this.classes
				]
			},
			visibleDate(){
				return this.$moment(this.localValue).format('DD/MM YYYY HH:mm')
			},
			minDate(){
				const date = new Date();
				return date.toISOString();
			}
		},
		data () {
			return {
				id: null,
				localValue: false,
			}
		},
		created()
		{
			this.localValue = this.value;
		},
		watch:{
			localValue(){
				this.$emit('input', this.localValue)
			}
		}
	}
</script>

<style lang="scss" scoped>
	
	.field-datetime{
		display: block;
		width: 100%;
		margin-bottom: 1.5rem;
		&.has-error{
			input{
				border-bottom: 1px solid $danger;
			}
		}
		input{
			display: block;
			::placeholder{
				color: $lightGray;
			}
		}
		.error{
			color: $danger;
			padding-left: 0px;
			font-size: 0.625rem;
		}
	}
	
	.form__group {
		position: relative;
		padding: 15px 0 0;
		margin-top: 0px;
	}
	
	.form__field {
		font-family: inherit;
		width: 100%;
		border: 0;
		border-bottom: 1px solid $lighterGray;
		outline: 0;
		font-size: 0.875rem;
		padding: 7px 0;
		background: transparent;
		transition: border-color 0.2s;
		
		&::placeholder {
			color: transparent;
		}
		
		&:placeholder-shown ~ .form__label {
			font-size: 0.875rem;
			cursor: text;
			top: 20px;
		}
	}
	
	.form__label {
		position: absolute;
		top: 5px;
		display: block;
		transition: 0.2s;
		font-size: 0.625rem;
		color: $lightGray;
		pointer-events: none;
	}
	
	.form__field:focus {
		~ .form__label {
			position: absolute;
			top: 5px;
			display: block;
			transition: 0.2s;
			font-size: 0.625rem;
		}
		padding-bottom: 7px;
	}
	/* reset input */
	.form__field{
		&:required,&:invalid { box-shadow:none; }
	}
	.form__field:disabled {
		color: $lightGray;
		-webkit-opacity: 1;
	}
	.form__field::placeholder{
		// We dont need color on the placeholder
		color: transparent !important;
	}
</style>

<style lang="scss">
	.field-datetime{
		.form__field{
			.vdatetime-input{
				border:none;
				max-width: 100%;
			}
		}
	}

</style>
